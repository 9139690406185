<template>
  <div class="page">
    <div class="header">
      <div class="wrap">
        <div class="inner">
          <div class="logo">
            <img src="@/assets/adminLogo.png" alt="" class="logo-img">
          </div>
          <div class="nav">
            <a-menu theme="dark" class="nav-menu" @select="handleChangeMenu" v-model:selectedKeys="activeKey"
              mode="horizontal" size="large">
              <template v-for="item in menus" :key="item.id">
                <a-menu-item :key="item.url" v-if="!item.sub_menu.length">{{ item.mc }}</a-menu-item>
                <a-sub-menu :key="item.mc" v-else :popupOffset="[-40, 0]">
                  <template #title>{{ item.mc }}</template>
                  <a-menu-item v-for="sub in item.sub_menu" :key="sub.url">{{ sub.mc }}</a-menu-item>
                </a-sub-menu>
              </template>
            </a-menu>
          </div>
          <div class="btns">
            <div class="label">
              <!-- <div class="user"></div> -->
              <img src="@/assets/icons/user.png" alt="" class="user">
              <div class="username">{{ userinfo?.phone || '' }}</div>
            </div>
            
            <div class="btn" @click="exit">
              <img src="@/assets/icons/out.png" alt="退出登录" class="icon">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subheader">

    </div>
    <div class="main">
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import service from '@/service/service';
import { mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      activeKey: [],
      menus: [],
    };
  },
  mounted() {
    if (!this.logoUrl) {
      this.getLogo();
    }
    this.getMenus();
  },
  computed: {
    ...mapState(["userinfo", "logoUrl"]),
  },
  methods: {
    ...mapMutations(["setLogoUrl", 'setUserinfo']),
    handleChangeMenu(e) {
      // console.log(e);
      this.$router.push(e.key);
    },
    async getMenus() {
      // const { data: menus } = await service.get_my_menu({ system_id: 20 });
      const menus = [
        {
          mc: "桌面",
          url: "/platform/home",
          sub_menu: []
        },
        {
          mc: "政策管理",
          url: "",
          sub_menu: [
            {
              mc: "政策标注",
              url: "/platform/home/article-set",
            },
            {
              mc: "政策匹配",
              url: "/platform/home/article-match",
            },
            {
              mc: "资讯回收",
              url: "/platform/home/article-recycle",
            },
            {
              mc: "标签分类",
              url: "/platform/home/type-manage",
            },
            {
              mc: "标签管理",
              url: "/platform/home/label-manage",
            },
            {
              mc: "产业类别",
              url: "/platform/home/industry-manage",
            },
            {
              mc: "企业类型",
              url: "/platform/home/type-company",
            },
            {
              mc: "财务指标",
              url: "/platform/home/financial-type",
            },
            {
              mc: "部门管理",
              url: "/platform/home/dept-manage",
            },
            {
              mc: "政策日志",
              url: "/platform/home/article-log",
            },

            
          ]
        },
        {
          mc: "系统管理",
          url: "",
          sub_menu: [
          {
              mc: "用户管理",
              url: "/platform/home/user-manage",
            },
            {
              mc: "整理用户",
              url: "/platform/home/user-admin-manage",
            },
            {
              mc: "角色管理",
              url: "/platform/home/role-manage",
            },
            {
              mc: "套餐管理",
              url: "/platform/home/package-setting",
            },
            {
              mc: "图标管理",
              url: "/platform/home/banner",
            },
            {
              mc: "支付配置",
              url: "/platform/home/payment-setting",
            },
            {
              mc: "渠道配置",
              url: "/platform/home/channel-setting",
            },
            
          ]
        },
        {
          mc: "业务管理",
          url: "",
          sub_menu: [
          {
              mc: "业务员",
              url: "/platform/home/salesman",
            },
            {
              mc: "业务详单",
              url: "/platform/home/bussiness",
            },
            {
              mc: "业务统计",
              url: "/platform/home/bussiness-amount",
            }
          ]
        },
        {
          mc: "财务管理",
          url: "",
          sub_menu: [
          {
              mc: "实付订单",
              url: "/platform/home/order-list",
            },
            {
              mc: "先票后款",
              url: "/platform/home/order-invoice",
            }
          ]
        },
        {
          mc: "历史菜单",
          url: "",
          sub_menu: [
          {
              mc: "公示管理",
              url: "/platform/home/article-gs",
            },
            
            {
              mc: "数据维护",
              url: "/platform/home/article-manage",
            },
            
            {
              mc: "项目类别",
              url: "/platform/home/type-study",
            },
            {
              mc: "申报项目",
              url: "/platform/home/type-industry",
            },
            {
              mc: "基础配置",
              url: "/platform/home/base-data",
              sub_menu: []
            },
            {
              mc: "菜单管理",
              url: "/platform/home/menu-manage",
              sub_menu: []
            },
          ]
        },
      ];
      this.menus = menus;
      this.activeKey = [menus?.[0]?.url];
    },
    async getLogo() {
      const { data } = await service.get_setting_info({ key: "system_logo" });
      // console.log(data);
      let files = data.icon ? JSON.parse(decodeURI(data.icon)) : [];
      this.logoUrl = files[0] || null;
      if (this.logoUrl) {
        this.setLogoUrl(this.logoUrl.url);
      }
    },
    exit() {
      window.sessionStorage.removeItem("token");
      this.setUserinfo(['userinfo', null]);
      this.$router.replace("/login-admin");
    }
  },
  components: {}
}
</script>
<style lang="less" scoped>
.page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header {
    flex: 0 0 auto;

    .wrap {
      height: 68px;
      // background: #fff;
      background: #003466;
      box-shadow: 0 0 16px rgba(51, 135, 255, .12);

      .inner {
        width: 1400px;
        margin: auto;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
          height: 44px;

          .logo-img {
            height: 100%;
            width: auto;
            vertical-align: top;
          }


        }

        .nav {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: center;

        }

        .btns {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .btn {
            width: 32px;
            height: 32px;
            margin-left: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .icon {
              width: 100%;
              // height:100%;
              height: auto;
              vertical-align: top;
            }
          }

          .label {
            background: @primary-color;
            // border-radius: @border-radius;
            border-radius: 10px;
            color: #fff;
            display: flex;
            align-items: center;
            padding: 0 6px;
            height: 32px;
            cursor: pointer;

            .user {
              width: 26px;
              height: 26px;
              margin-right: 6px;
            }

            .username {
              font-size: 20px;
              letter-spacing: 1px;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .main {
    flex: 1;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .slide {
      width: 278px;
      padding: 24px;
    }
  }
}
</style>